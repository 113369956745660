<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{name: 'PartnerList'}">
              {{ displayText('partner.partner_management', '合作夥伴管理') }}
            </b-breadcrumb-item>

            <b-breadcrumb-item active>{{ displayText('partner.partner_staff_list', '夥伴帳號列表') }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="d-flex flex-column flex-xl-row align-items-start align-items-xl-center justify-content-between mb-4 mb-xl-2">
        <h4 class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold">
          {{ displayText('partner.partner_staff_list', '夥伴帳號列表') }}
        </h4>
        <div
          class="
            col-12 col-xl-8
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.PARTNER_STAFF_CREATE]) && partner_id"
            class="flex-shrink-0 mb-2 mb-xl-0 mr-3"
            variant="primary"
            :to="{ name: 'PartnerStaffCreate', params: { partnerId: partner_id } }"
          ><i class="fa fa-plus"></i>{{ displayText('partner.partner_staff_add', '新增夥伴帳號') }}</b-button
          >

          <b-form-select
            v-if="partnerDropdownVisible"
            class="mr-3 mb-2 mb-xl-0"
            v-model="partner_id"
            :options="partners"
            :disabled="isDropdownDisabled"
          ></b-form-select>

          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input v-bind:placeholder="displayText('partner.staff_search_placeholder', '請輸入姓名、手機號碼、email、員工編號')" v-model="query.keyword"
                          v-on:keyup.enter="getPartnerStaffs()"></b-form-input>
            <b-input-group-append>
              <b-button @click="getPartnerStaffs()"><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="partnerStaffs"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-dropdown id="ddown23" variant="secondary">
                    <template slot="button-content">
                      <i class="mdi mdi-menu"></i>
                    </template>
                    <b-dropdown-item
                      v-if="checkPermission([consts.PARTNER_STAFF_LIST_VIEW])"
                      :to="{
                      name: 'PartnerStaffView',
                      params: { staff_id: data.item.id, partnerId: partner_id },
                    }"
                    >
                      {{ displayText('partner.view', '查看') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="checkPermission([consts.PARTNER_STAFF_MODIFY])"
                      variant="inverse-warning"
                      :to="{
                      name: 'PartnerStaffEdit',
                      params: { staff_id: data.item.id, partnerId: partner_id },
                    }"
                    >
                    {{ displayText('partner.edit', '編輯') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="checkPermission([consts.PARTNER_STAFF_MODIFY])"
                      variant="inverse-warning"
                      :to="{
                      name: 'PartnerStaffSetPartner',
                      params: { staff_id: data.item.id, partnerId: partner_id },
                    }"
                    >
                      {{ displayText('partner.set_belong_partner_company', '設定所屬夥伴公司') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="checkPermission([consts.PARTNER_STAFF_DELETE])"
                      variant="inverse-danger"
                      @click="deleteStaff(data.item)"
                    >
                      {{ displayText('partner.delete', '刪除') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="container" style="margin-top: 80px">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 col-md-auto mb-3">
              <span>目前第 {{ currentPage }} 頁 / 總共 {{ Math.ceil(totalRows / query.per_page) }} 頁</span>
            </div>

            <div class="col-12 col-md-auto mb-3">
              <b-pagination
                class="my-auto"
                v-if="totalRows > 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="query.per_page"
              ></b-pagination>
            </div>

            <div class="col-12 col-md-auto mb-3">
              <span>每頁顯示數量：</span>
              <b-form-select
                v-model="query.per_page"
                :options="perPageOption"
                class="d-inline-block"
                style="width: auto;"

              ></b-form-select>
              <span> / 總數量: {{ totalRows }}</span>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'
import partnerStaffApi from "@/apis/partner-staff";
import staffApi from "@/apis/staff";
import { displayText } from '@/utils/dashboardUtils'

export default {
  data() {
    return {
      consts: consts,
      currentPage: 1,
      totalRows: 1,
      perPageOption: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      showLoading: true,
      showLoadingUpload: false,
      partners: [{value: null, text: displayText('partner.select_partner', '選擇夥伴公司')}],
      partner_id: null,
      partnerStaffs: [],
      partnerStaff: null,
      initialized: false,
      query: {
        per_page: 10,
        keyword: "",
        page: 1,
      },
      fields: [
        {
          key: "avatar_url",
          label: "",
        },
        {
          key: "employee_code",
          label: displayText('partner.worker_code', '員工編號'),
          sortable: true,
        },
        {
          key: "name",
          label: displayText('partner.worker_name', '員工姓名'),
        },
        {
          key: "mobile_phone",
          label: displayText('partner.mobile', '手機'),
        },
        {
          key: "email",
          label: displayText('partner.email', 'Email'),
        },
        {
          key: "display_status",
          label: displayText('partner.status', '狀態'),
        },
        {
          key: "actions",
          label: displayText('partner.action', '操作'),
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    partnerDropdownVisible() {
      // 若有 PARTNER_LIST_VIEW 權限，則出現夥伴公司的下拉選單
      // 若無 PARTNER_LIST_VIEW 權限，但操作者在 partner_staff 有資料，下拉選單就列出對應的 partner 資料。
      return this.checkPermission([consts.PARTNER_LIST_VIEW]) || this.partners.length > 1;
    },
    isDropdownDisabled() {
      // 若只對應到一個 partner，則停用下拉，預設就選這個
      return this.partners.length === 2;
    },
  },
  watch: {
    currentPage(newPage) {
      if (!this.initialized || newPage == this.query.page) return;
      this.query.page = newPage;
    },
    query: {
      handler() {
        if (!this.initialized) return;
        this.updateUrl(this.query);
        this.getPartnerStaffs();
      },
      deep: true
    },
    partner_id(newPartnerId, oldPartnerId) {
      if (!this.initialized || newPartnerId === oldPartnerId) return;
      this.updateUrl(this.query);
      if (oldPartnerId) {
        this.getPartnerStaffs();
      }
    },
  },
  async created() {
    this.partner_id = this.$route.params.partnerId || this.partner_id;

    let updatedQuery = {
      ...this.query,
      ...this.$route.query,
      page: parseInt(this.$route.query.page, 10) || this.query.page,
    };

    this.query = updatedQuery;
    await this.init();
    this.currentPage = this.query.page;
    this.initialized = true;
  },
  methods: {
    displayText,
    async init() {
      await this.fetchPartner();
      await this.getPartnerStaffs();
    },
    async getPartnerStaffs() {
      if (!this.partner_id) {
        this.showLoading = false;
        return;
      }
      this.showLoading = true;
      try {
        let params = {
          partner_id: this.partner_id,
          ...this.query
        };

        const { data } = await partnerStaffApi.getPartnerStaffs(this.organization, params);
        this.partnerStaffs = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: displayText('partner.api_failed', '讀取資料錯誤'),
          });
        }
      } finally {
        this.showLoading = false;
      }
    },
    async deleteStaff(staff) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>${displayText('partner.worker_code', '員工編號')}：${staff.employee_code}</div>
                <div>${displayText('partner.worker_name', '員工姓名')}：${staff.name}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await staffApi.removeStaff(this.organization, staff);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 " + staff.name,
              });
              await this.getPartnerStaffs();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    async fetchPartner() {
      if (this.partners.length > 1) return;

      try {
        let params = {
          order_by: 'partner_code',
        };
        const { data } = await partnerStaffApi.getPartnerList(this.organization, params);
        data.data.forEach((partner) => {
          this.partners.push({
            text: partner.partner_code + ' ' + partner.name,
            value: partner.id,
          });
        });

        // 若只有一個夥伴公司，則預設選取
        if (data.data.length === 1) {
          this.partner_id = data.data[0].id;
        }
      } catch (error) {
        console.error(error);
        this.$swal('錯誤', displayText('partner.api_failed', '讀取資料錯誤'), 'error');
      }
    },

    checkPermission (permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },

    updateUrl(query) {
      // filter out null value
      const filteredQuery = Object.keys(query).reduce((acc, key) => {
        if (query[key] !== null && query[key] !== undefined && query[key] !== "" && !(key === 'page' && query[key] === 1)) {
          acc[key] = query[key];
        }
        return acc;
      }, {});
      const currentRoute = this.$router.currentRoute;
      let needsNavigation = currentRoute.name !== "PartnerStaffList" ||
        JSON.stringify(currentRoute.query) !== JSON.stringify(filteredQuery) ||
        (this.partner_id && currentRoute.params.partnerId !== this.partner_id);

      if (needsNavigation && this.partner_id) {
        this.$router.push({ name: "PartnerStaffList", query: filteredQuery, params: { partnerId: this.partner_id }});
      }
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
